import React, { useState, useEffect } from "react";
import Layout from "Components/Layout";
import Swal from "sweetalert2";
import Subloader from "Components/Loader/subload/loader";
import Loader from "Components/Loader/Loader";
import moment from "moment";
import timezone from "moment-timezone";

import {
    getApproveRequests,
    GetPermissions,
    unlockUser,
} from "Services/ServiceRequest";
import { MdRemoveRedEye } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import ModalCustom from "./Modal";
import { useForm } from "react-hook-form";

function Request() {
    const [loading, setLoading] = useState(true);
    const [Permissions, setPermissions] = useState({ RolePermissions: [] });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [Status, setStatus] = useState(2);
    const [subLoad, setSubLoad] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [PermissionNames, setPermissionNames] = useState([]);
    const [modal, setModal] = useState(false);
    const [selected, SetSelected] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [requests, setRequests] = useState([]);
    const UtTimeZoneConvertor = (utcTime) => {
        return timezone
            .utc(utcTime)
            .tz("America/New_York")
            .format("YYYY-MM-DD HH:mm");
    };
    const GetRequests = async (status) => {
        setLoading(true);
        try {
            const response = await getApproveRequests(status);

            if (response?.data?.Status === 1) {
                setRequests(response?.data?.Requests);
                setRequestData(response?.data?.Requests);
                if (Permissions.RolePermissions.length == 0) {
                    getPermissions();
                } else {
                    setSubLoad(false);
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const getPermissions = async () => {
        // setLoading(true);
        try {
            const response = await GetPermissions(1);

            if (response?.data?.Status === 1) {
                setPermissions(response?.data);
            } else {
            }
            setLoading(false);
            setSubLoad(false);
        } catch (err) {
            setSubLoad(false);
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const changeHandler = (e) => {
        let filteredData = Permissions.RolePermissions.filter(
            (item) => item.PageName == e.target.value
        );
        setPermissionNames(
            filteredData[0].Sections.map((item) => item.SectionName)
        );
        // filteredData.filterData.flatMap(item);
        // setPermissionNames(
        //     Permissions.RolePermissions.filter(
        //         (item) => item.PageName == e.target.value
        //     )[0]
        // );
    };
    useEffect(() => {
        GetRequests(Status);
    }, [Status]);
    const buttons = [
        { value: 2, label: "Pending" },
        { value: 1, label: "Approved" },
        { value: 3, label: "Rejected" },
    ];
    const onSubmit = (data) => {
        console.log(data);
        let filterData = requestData;
        if (data?.fullName && data.fullName.length > 0) {
            filterData = filterData.filter((item) =>
                String(item.fullName)
                    .toLowerCase()
                    .includes(String(data?.fullName).toLowerCase())
            );
        }
        if (data?.emailId && data.emailId.length > 0) {
            filterData = filterData.filter((item) =>
                String(item.emailId)
                    .toLowerCase()
                    .includes(String(data?.emailId).toLowerCase())
            );
        }
        if (data?.Page && data.Page.length > 0) {
            filterData = filterData.filter(
                (item) =>
                    String(item.page).toLowerCase() ==
                    String(data?.Page).toLowerCase()
            );
        }
        if (data?.PermissionName && data.PermissionName.length > 0) {
            filterData = filterData.filter(
                (item) =>
                    String(item.permissionName).toLowerCase() ==
                    String(data?.PermissionName).toLowerCase()
            );
        }
        if (startDate && endDate) {
            filterData = filterData.filter((item) => {
                const itemDate = moment(item.createdAt);
                return itemDate.isBetween(startDate, endDate, null, "[]");
            });
        }
        setRequests(filterData);
    };

    return (
        <Layout
            Active={"approverequest"}
            title={"Approve Requests"}
            showBackButton={false}>
            {subLoad && <Subloader />}
            {modal && (
                <ModalCustom
                    getRequest={GetRequests}
                    setsubload={setSubLoad}
                    setModal={setModal}
                    selected={selected}
                    setSelected={SetSelected}
                    showModal={modal}
                    Status={Status}
                />
            )}
            <div className="request_Tabs">
                {buttons.map((item) => (
                    <button
                        className={Status == item.value ? "Active-tab" : ""}
                        onClick={() => setStatus(item.value)}>
                        {item?.label}
                    </button>
                ))}
            </div>
            <div className="Main_container">
                <Loader loading={loading}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="filter-form">
                        <div className="filter-form-control">
                            <label htmlFor="">Name</label>
                            <input
                                type="text"
                                {...register("fullName")}
                                placeholder="Search by Name"
                            />
                        </div>
                        <div className="filter-form-control">
                            <label htmlFor="">Email Address</label>
                            <input
                                type="text"
                                {...register("emailId")}
                                placeholder="Search by Email"
                            />
                        </div>
                        <div
                            className="filter-form-control"
                            style={{ zIndex: 5 }}>
                            <label htmlFor="">Request Date</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(dates) => {
                                    const [start, end] = dates;
                                    setStartDate(start);
                                    setEndDate(end);
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                            />
                        </div>
                        <div className="filter-form-control">
                            <label htmlFor="">Page Name</label>
                            <select
                                name=""
                                id=""
                                {...register("Page")}
                                onChange={changeHandler}>
                                <option value="">Select Page</option>
                                {Permissions.RolePermissions.map((item) => (
                                    <option value={item.PageName}>
                                        {item.PageName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {
                            <div className="filter-form-control">
                                <label htmlFor="">Permission Name</label>
                                <select {...register("PermissionName")}>
                                    <option value="">Select Permission</option>
                                    {PermissionNames.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        }
                        <button
                            className="form_control_filter_button"
                            type="submit">
                            Submit
                        </button>
                        <button
                            className="form_control_filter_button"
                            type="button"
                            style={{ background: "red", color: "white" }}
                            onClick={() => {
                                reset();
                                setStartDate(null);
                                setEndDate(null);
                                setRequests(requestData);
                            }}>
                            Clear
                        </button>
                    </form>
                    <div
                        className="Table_layout"
                        style={{ height: "400px", zIndex: 1 }}>
                        <table>
                            <thead className="sticky-header">
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email ID</th>
                                    <th>Page</th>
                                    <th>permission Name</th>
                                    <th>Requested Date</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requests.length > 0 ? (
                                    requests.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.emailId}</td>
                                            <td>{item.page}</td>
                                            <td>{item.permissionName}</td>
                                            <td>
                                                {UtTimeZoneConvertor(
                                                    item?.createdAt
                                                )}
                                            </td>
                                            <td>
                                                {item?.approvalStatus == 2 && (
                                                    <span className="status pending">
                                                        Pending
                                                    </span>
                                                )}
                                                {item?.approvalStatus == 1 && (
                                                    <span className="status approved">
                                                        Accepted
                                                    </span>
                                                )}
                                                {item?.approvalStatus == 3 && (
                                                    <span className="status rejected">
                                                        Rejected
                                                    </span>
                                                )}
                                            </td>

                                            <td>
                                                <button
                                                    className="approve-view"
                                                    onClick={() => {
                                                        SetSelected(item);
                                                        setModal(true);
                                                    }}>
                                                    <MdRemoveRedEye />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {" "}
                                        <td colSpan={7}>No Data Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Loader>
            </div>
        </Layout>
    );
}

export default Request;
